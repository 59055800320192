import { callApi, encodeFilters } from './FetchUtil';

const PATH = 'api/';

export const getUser = (hsuccess, herror) => {
    const url = PATH + 'getuser';
    callApi(url, { method: 'POST', body: 'tr' }, hsuccess, herror);
}

export const login = (data, hsuccess, herror, hcomplete) => {
    const url = PATH + 'login?t=s';
    callApi(url, { data }, hsuccess, herror, hcomplete);
}

export const signup = (data, hsuccess, herror, hcomplete) => {
    const url = PATH + 'signup?t=s';
    callApi(url, { data }, hsuccess, herror, hcomplete);
}

export const passwordUpdate = (model, hsuccess, herror, hcomplete) => {
    const url = PATH + 'updatepassword?t=s';
    callApi(url, { data: model }, hsuccess, herror, hcomplete);
}

export const goauth = (token, hsuccess, herror, hcomplete) => {
    const url = PATH + 'goauth?t=s';
    callApi(url, { data: token }, hsuccess, herror, hcomplete);
}

export const logout = (hsuccess, herror) => {
    const url = PATH + 'logout?c=true';
    callApi(url, {}, hsuccess, herror);
}

export const tuInvitationGet = (data, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/tuinvitation';
    callApi(url, { data }, hsuccess, herror, hcomplete);
}

export const tuInvitationAccept = (data, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/tuinvitationaccept';
    callApi(url, { data }, hsuccess, herror, hcomplete);
}

export const activateTenant = (data, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/activatetenant';
    callApi(url, { data }, hsuccess, herror, hcomplete);
}

export const mailControl = (data, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/mailcontrol';
    callApi(url, { data }, hsuccess, herror, hcomplete);
}

export const incomingmailControl = (data, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/incomingmailcontrol';
    callApi(url, { data }, hsuccess, herror, hcomplete);
}

export const validation = (data, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/validation';
    callApi(url, { data }, hsuccess, herror, hcomplete);
}


export const packageList = (tn, rsid, hsuccess, herror, hcomplete) => {
    var url = PATH + 'bulut/packagelist?tn='+tn;
    if (rsid!=undefined)
        url += '&rs='+rsid;
    callApi(url, {}, hsuccess, herror, hcomplete);
}

export const iller = (country,hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/il?c='+country;
    callApi(url, {}, hsuccess, herror, hcomplete);
}

export const ilceler = (il, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/ilce?il=' + il;
    callApi(url, {}, hsuccess, herror, hcomplete);
}

export const mahalleler = (il, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/mahalle?ilce=' + il;
    callApi(url, {}, hsuccess, herror, hcomplete);
}

export const acStore = (query, params, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/acstore';
    callApi(url + '?t=' + query, {}, hsuccess, herror, hcomplete);
}

export const addressList = (page, filters, sortDef, rows, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/useraddress';
    var sort = "";
    if (sortDef) {
        Object.keys(sortDef).map((key, index) => {
            sort += "&sidx=" + key;
            if (sortDef[key])
                sort += "&sord=desc";
        });
    }
    var jsonfilters = filters ? "&" + encodeFilters(filters) : "";
    callApi(url + '?page=' + Math.max(page, 1) + '&rows=' + rows + jsonfilters + sort, {}, hsuccess, herror, hcomplete);
}

export const userList = (page, filters, sortDef, rows, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/biuser';
    var sort = "";
    if (sortDef) {
        Object.keys(sortDef).map((key, index) => {
            sort += "&sidx=" + key;
            if (sortDef[key])
                sort += "&sord=desc";
        });
    }
    var jsonfilters = filters ? "&" + encodeFilters(filters) : "";
    callApi(url + '?page=' + Math.max(page, 1) + '&rows=' + rows + jsonfilters + sort, {}, hsuccess, herror, hcomplete);
}
export const userGet = (id, hsuccess, herror, hcomplete) => {
    const url = 'api/site/biuser';
    callApi(url + '/joined?pk=' + id, {}, hsuccess, herror, hcomplete);
}
export const userGetFindByMail = (email, hsuccess, herror, hcomplete) => {
    const url = 'api/site/biuserfindbymail';
    callApi(url + '?email=' + email, {}, hsuccess, herror, hcomplete);
}
export const userPersist = (model, hsuccess, herror, hcomplete) => {
    var url = 'api/site/biuser';
    if (model.id != undefined)
        url += "/upd";
    else
        url += "/add";
    callApi(url, { data: model }, hsuccess, herror, hcomplete);
}
export const userDelete = (pk, hsuccess, herror, hcomplete) => {
    const url = 'api/site/biuser/del?pk=' + pk;
    callApi(url, {}, hsuccess, herror, hcomplete);
}

export const userTenantList = (page, filters, sortDef, rows, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/usertenants';
    var sort = "";
    if (sortDef) {
        Object.keys(sortDef).map((key, index) => {
            sort += "&sidx=" + key;
            if (sortDef[key])
                sort += "&sord=desc";
        });
    }
    var jsonfilters = filters ? "&" + encodeFilters(filters) : "";
    callApi(url + '?page=' + Math.max(page, 1) + '&rows=' + rows + jsonfilters + sort, {}, hsuccess, herror, hcomplete);
}

export const userAddressGet = (id, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/useraddress/get?pk=' + id;
    callApi(url, {}, hsuccess, herror, hcomplete);
}
export const userAddressPersist = (model, hsuccess, herror, hcomplete) => {
    var url = 'api/site/useraddress';
    if (model.id != undefined)
        url += "/upd";
    else
        url += "/add";
    callApi(url, { data: model }, hsuccess, herror, hcomplete);
}
export const userAddressDelete = (pk, hsuccess, herror, hcomplete) => {
    const url = 'api/site/useraddress/del?pk=' + pk;
    callApi(url, {}, hsuccess, herror, hcomplete);
}
export const podDatabaseList = (page, filters, sortDef, rows, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/poddbs';
    var sort = "";
    if (sortDef) {
        Object.keys(sortDef).map((key, index) => {
            sort += "&sidx=" + key;
            if (sortDef[key])
                sort += "&sord=desc";
        });
    }
    var jsonfilters = filters ? "&" + encodeFilters(filters) : "";
    callApi(url + '?page=' + Math.max(page, 1) + '&rows=' + rows + jsonfilters + sort, {}, hsuccess, herror, hcomplete);
}
export const fixPartitions = (pk, pid, hsuccess, herror, hcomplete) => {
    const url = 'api/site/fixparts?pk=' + pk + '&pid='+pid;
    callApi(url, {}, hsuccess, herror, hcomplete);
}

export const tenantStorePersist = (model, hsuccess, herror, hcomplete) => {
    var url = 'api/site/tenantstore';
    if (model.id != undefined)
        url += "/upd";
    else
        url += "/add";
    callApi(url, { data: model }, hsuccess, herror, hcomplete);
}

export const podList = (page, filters, sortDef, rows, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/pod';
    var sort = "";
    if (sortDef) {
        Object.keys(sortDef).map((key, index) => {
            sort += "&sidx=" + key;
            if (sortDef[key])
                sort += "&sord=desc";
        });
    }
    var jsonfilters = filters ? "&" + encodeFilters(filters) : "";
    callApi(url + '?page=' + Math.max(page, 1) + '&rows=' + rows + jsonfilters + sort, {}, hsuccess, herror, hcomplete);
}
export const podGet = (id, hsuccess, herror, hcomplete) => {
    const url = 'api/site/pod';
    callApi(url + '/get?pk=' + id, {}, hsuccess, herror, hcomplete);
}
export const podPersist = (model, hsuccess, herror, hcomplete) => {
    var url = 'api/site/pod';
    if (model.id != undefined)
        url += "/upd";
    else
        url += "/add";
    callApi(url, { data: model }, hsuccess, herror, hcomplete);
}
export const podDelete = (pk, hsuccess, herror, hcomplete) => {
    const url = 'api/site/pod/del?pk=' + pk;
    callApi(url, {}, hsuccess, herror, hcomplete);
}
export const podService = (pk, hsuccess, herror, hcomplete) => {
    const url = 'api/site/podservice?pk=' + pk;
    callApi(url, {}, hsuccess, herror, hcomplete);
}

export const purchaseRequestList = (page, filters, sortDef, rows, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/purchaserequest';
    var sort = "";
    if (sortDef) {
        Object.keys(sortDef).map((key, index) => {
            sort += "&sidx=" + key;
            if (sortDef[key])
                sort += "&sord=desc";
        });
    }
    var jsonfilters = filters ? "&" + encodeFilters(filters) : "";
    callApi(url + '?page=' + Math.max(page, 1) + '&rows=' + rows + jsonfilters + sort, {}, hsuccess, herror, hcomplete);
}
export const purchaseRequestGet = (id, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/purchaserequest/get?pk=' + id;
    callApi(url, {}, hsuccess, herror, hcomplete);
}
export const purchaseRequestPersist = (model, hsuccess, herror, hcomplete) => {
    var url = 'api/site/purchaserequest';
    if (model.id != undefined) {
        url += "/upd";
    }
    else
        url += "/add";
    callApi(url, { data: model }, hsuccess, herror, hcomplete);
}

export const resellerList = (page, filters, sortDef, rows, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/reseller';
    var sort = "";
    if (sortDef) {
        Object.keys(sortDef).map((key, index) => {
            sort += "&sidx=" + key;
            if (sortDef[key])
                sort += "&sord=desc";
        });
    }
    var jsonfilters = filters ? "&" + encodeFilters(filters) : "";
    callApi(url + '?page=' + Math.max(page, 1) + '&rows=' + rows + jsonfilters + sort, {}, hsuccess, herror, hcomplete);
}
export const resellerDelete = (pk, hsuccess, herror, hcomplete) => {
    const url = 'api/site/reseller/del?pk=' + pk;
    callApi(url, {}, hsuccess, herror, hcomplete);
}
export const resellerFind = (hsuccess, herror, hcomplete) => {
    const url = 'api/reseller';
    callApi(url, {}, hsuccess, herror, hcomplete);
}
export const resellerGet = (id, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/reseller/get?pk=' + id;
    callApi(url, {}, hsuccess, herror, hcomplete);
}
export const resellerPersist = (model, hsuccess, herror, hcomplete) => {
    var url = 'api/site/reseller';
    if (model.id != undefined)
        url += "/upd";
    else
        url += "/add";
    callApi(url, { data: model }, hsuccess, herror, hcomplete);
}
export const resellerUpdate = (model, hsuccess, herror, hcomplete) => {
    var url = PATH + 'site/resellerUpdate';
    callApi(url, { data: model }, hsuccess, herror, hcomplete);
}

export const templateList = (page, filters, sortDef, rows, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/template';
    var sort = "";
    if (sortDef) {
        Object.keys(sortDef).map((key, index) => {
            sort += "&sidx=" + key;
            if (sortDef[key])
                sort += "&sord=desc";
        });
    }
    var jsonfilters = filters ? "&" + encodeFilters(filters) : "";
    callApi(url + '?page=' + Math.max(page, 1) + '&rows=' + rows + jsonfilters + sort, {}, hsuccess, herror, hcomplete);
}
export const templateGet = (id, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/template/get?pk=' + id;
    callApi(url, {}, hsuccess, herror, hcomplete);
}
export const templatePersist = (model, hsuccess, herror, hcomplete) => {
    var url = 'api/site/template';
    if (model.id != undefined)
        url += "/upd";
    else
        url += "/add";
    console.log("modelllik", model);
    callApi(url, { data: model }, hsuccess, herror, hcomplete);
}
export const templateDelete = (pk, hsuccess, herror, hcomplete) => {
    const url = 'api/site/template/del?pk=' + pk;
    callApi(url, {}, hsuccess, herror, hcomplete);
}
export const templateUpdate = (model, hsuccess, herror, hcomplete) => {
    var url = PATH + 'site/templateUpdate';
    callApi(url, { data: model }, hsuccess, herror, hcomplete);
}
export const queueLicense= (id,tn,tx, hsuccess, herror, hcomplete) => {
    var url = PATH + 'site/licqueue?id='+id+"&tn="+tn+"&tx="+tx;
    callApi(url, { method:'HEAD' }, hsuccess, herror, hcomplete);
}
export const tenantInvoiceList = (page, filters, sortDef, rows, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/licenseinvoice';
    var sort = "";
    if (sortDef) {
        Object.keys(sortDef).map((key, index) => {
            sort += "&sidx=" + key;
            if (sortDef[key])
                sort += "&sord=desc";
        });
    }
    var jsonfilters = filters ? "&" + encodeFilters(filters) : "";
    callApi(url + '?page=' + Math.max(page, 1) + '&rows=' + rows + jsonfilters + sort, {}, hsuccess, herror, hcomplete);
}
export const tenantInvoiceGet = (id, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/licenseinvoice/get?pk=' + id;
    callApi(url, {}, hsuccess, herror, hcomplete);
}
export const tenantInvoicePersist = (model, hsuccess, herror, hcomplete) => {
    var url = 'api/site/licenseinvoice';
    if (model.id != undefined)
        url += "/upd";
    else
        url += "/add";
    console.log("model", model);
    callApi(url, { data: model }, hsuccess, herror, hcomplete);
}
export const invoiceDelete = (pk, hsuccess, herror, hcomplete) => {
    const url = 'api/site/licenseinvoice/del?pk=' + pk;
    callApi(url, {}, hsuccess, herror, hcomplete);
}

export const tenantInvoiceLineList = (page, filters, sortDef, rows, hsuccess, herror, hcomplete) => {

    const url = PATH + 'site/licenseinvoiceline';
    var sort = "";
    if (sortDef) {
        Object.keys(sortDef).map((key, index) => {
            sort += "&sidx=" + key;
            if (sortDef[key])
                sort += "&sord=desc";
        });
    }
    var jsonfilters = filters ? "&" + encodeFilters(filters) : "";
    callApi(url + '?page=' + Math.max(page, 1) + '&rows=' + rows + jsonfilters + sort, {}, hsuccess, herror, hcomplete);
}
export const tenantInvoiceLineGet = (id, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/licenseinvoiceline/get?pk=' + id;
    callApi(url, {}, hsuccess, herror, hcomplete);
}
export const tenantInvoiceLinePersist = (model, hsuccess, herror, hcomplete) => {
    var url = 'api/site/licenseinvoiceline';
    if (model.id != undefined)
        url += "/upd";
    else
        url += "/add";
    console.log("model", model);
    callApi(url, { data: model }, hsuccess, herror, hcomplete);
}
export const invoiceLineDelete = (pk, hsuccess, herror, hcomplete) => {
    const url = 'api/site/licenseinvoiceline/del?pk=' + pk;
    callApi(url, {}, hsuccess, herror, hcomplete);
}
export const tenantLicCheck = (id, tax, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/tenantlicheck';
    callApi(url + '?t=' + tax + '&id=' + id, {}, hsuccess, herror, hcomplete);
}
export const tenantLicInvList = (page, filters, sortDef, rows, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/tenantlicinvoices';
    var sort = "";
    if (sortDef) {
        Object.keys(sortDef).map((key, index) => {
            sort += "&sidx=" + key;
            if (sortDef[key])
                sort += "&sord=desc";
        });
    }
    var jsonfilters = filters ? "&" + encodeFilters(filters) : "";
    callApi(url + '?page=' + Math.max(page, 1) + '&rows=' + rows + jsonfilters + sort, {}, hsuccess, herror, hcomplete);
}
export const tenantList = (page, filters, sortDef, rows, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/tenants';
    var sort = "";
    if (sortDef) {
        Object.keys(sortDef).map((key, index) => {
            sort += "&sidx=" + key;
            if (sortDef[key])
                sort += "&sord=desc";
        });
    }
    var jsonfilters = filters ? "&" + encodeFilters(filters) : "";
    callApi(url + '?page=' + Math.max(page, 1) + '&rows=' + rows + jsonfilters + sort, {}, hsuccess, herror, hcomplete);
}
export const tenantGet = (id, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/tenant?pk=' + id;
    callApi(url, {}, hsuccess, herror, hcomplete);
}

export const tenantUpdate = (model, hsuccess, herror, hcomplete) => {
    var url = PATH + 'site/tenantupdate';
    callApi(url, { data: model }, hsuccess, herror, hcomplete);
}
export const tenantClearDemo = (id, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/tenantcleardemo?pk=' + id;
    callApi(url, { method: "DELETE" }, hsuccess, herror, hcomplete);
}
export const tenantDeleteDemo = (id, title, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/tenantdeletedemo?pk=' + id + '&adi=' + title;
    callApi(url, { method: "DELETE" }, hsuccess, herror, hcomplete);
}
export const tenantDemoUpdate = (model, hsuccess, herror, hcomplete) => {
    var url = PATH + 'site/tenantdemoupdate';
    callApi(url, { data: model }, hsuccess, herror, hcomplete);
}
export const tenantStoreDemoUpdate = (model, hsuccess, herror, hcomplete) => {
    var url = PATH + 'site/tenantstoredemoupdate';
    callApi(url, { data: model }, hsuccess, herror, hcomplete);
}

export const tenantStoreGet = (id, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/tenantstore/get?pk=' + id;
    callApi(url, {}, hsuccess, herror, hcomplete);
}
export const tenantStoreList = (page, filters, sortDef, rows, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/tenantstores';
    var sort = "";
    if (sortDef) {
        Object.keys(sortDef).map((key, index) => {
            sort += "&sidx=" + key;
            if (sortDef[key])
                sort += "&sord=desc";
        });
    }
    var jsonfilters = filters ? "&" + encodeFilters(filters) : "";
    callApi(url + '?page=' + Math.max(page, 1) + '&rows=' + rows + jsonfilters + sort, {}, hsuccess, herror, hcomplete);
}

export const packagemanagementGet = (id, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/packagemanagementget?pk=' + id;
    callApi(url, {}, hsuccess, herror, hcomplete);
}

export const featureList = (page, filters, sortDef, rows, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/featurelist';
    var sort = "";
    if (sortDef) {
        Object.keys(sortDef).map((key, index) => {
            sort += "&sidx=" + key;
            if (sortDef[key])
                sort += "&sord=desc";
        });
    }
    var jsonfilters = filters ? "&" + encodeFilters(filters) : "";
    callApi(url + '?page=' + Math.max(page, 1) + '&rows=' + rows + jsonfilters + sort, {}, hsuccess, herror, hcomplete);
}

export const packagefeatureGet = (id, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/packagefeatureget?pk=' + id;
    callApi(url, {}, hsuccess, herror, hcomplete);
}

export const packagefeatureDelete = (fid, pkgid, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/packagefeaturedelete?fid=' + fid + '&pkgid=' + pkgid;
    callApi(url, {}, hsuccess, herror, hcomplete);
}

export const packagefeatureDeleteById = (id, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/packagefeaturedeletebyid?pk=' + id;
    callApi(url, {}, hsuccess, herror, hcomplete);
}

export const featureGet = (id, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/featureget?pk=' + id;
    callApi(url, {}, hsuccess, herror, hcomplete);
}

export const featureSave = (model, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/featuresave';
    callApi(url, { data: model }, hsuccess, herror, hcomplete);
}

export const resellers = (did, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/dealers?did=' + did;
    callApi(url, {}, hsuccess, herror, hcomplete);
}

export const dealer = (hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/dealer';
    callApi(url, {}, hsuccess, herror, hcomplete);
}

export const dealerFindCity = (cityId, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/dealerfindcity?cityId=' + cityId;
    callApi(url, {}, hsuccess, herror, hcomplete);
}

export const startPaymentBulut = (data, hsuccess, herror, hcomplete) => {
    const url = PATH + "bulut/start";
    callApi(url, { data }, hsuccess, herror, hcomplete);
}
export const printProposal = (id, hsuccess, herror, hcomplete) => {
    const url = PATH + "bulut/print?id=" + id;
    callApi(url, {}, hsuccess, herror, hcomplete);
}
export const sendProposal = (id, tos, hsuccess, herror, hcomplete) => {
    const url = PATH + "bulut/email?id=" + id+'&tos='+encodeURIComponent(tos);
    callApi(url, {}, hsuccess, herror, hcomplete);
}
export const fetchLicense = (id, hsuccess, herror, hcomplete) => {
    const url = PATH + "bulut/license?id=" + id;
    callApi(url, {}, hsuccess, herror, hcomplete);
}

export const startPaymentECredit = (tid, cid, partner, hsuccess, herror, hcomplete) => {
    var url = "api/ecredit/start?tid="+tid+"&cid=" + cid;
    if (partner)
        url += "&partner=" + partner;
    callApi(url, {}, hsuccess, herror, hcomplete);
}

export const forgotpassmail = (email, hsuccess, herror, hcomplete) => {
    const url = PATH + 'forgotpassword?email=' + email;
    callApi(url, {} , hsuccess, herror, hcomplete);
}
export const profileupdate = (model, hsuccess, herror, hcomplete) => {
    const url = PATH + 'profileupdate';
    callApi(url, { data: model } , hsuccess, herror, hcomplete);
}
export const registerReseller = (model, id, hsuccess, herror, hcomplete) => {
    const url = PATH + 'registerReseller';
    callApi(url, { data: model } , id, hsuccess, herror, hcomplete);
}
export const bidprotection = (model, id, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/bidprotection/add';
    callApi(url, { data: model } , id, hsuccess, herror, hcomplete);
}
export const registerSalesclerk = (model, hsuccess, herror, hcomplete) => {
    const url = PATH + 'registerSalesclerk';
    callApi(url, { data: model } , hsuccess, herror, hcomplete);
}
export const legalHolidayEdit = (data, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/legalholiday';
    callApi(url, { data }, hsuccess, herror, hcomplete);
}
export const legalHolidayList = (page, filters, sortDef, rows, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/legalholidaylist';
    var sort = "";
    if (sortDef) {
        Object.keys(sortDef).map((key, index) => {
            sort += "&sidx=" + key;
            if (sortDef[key])
                sort += "&sord=desc";
        });
    }
    var jsonfilters = filters ? "&" + encodeFilters(filters) : "";
    callApi(url + '?page=' + Math.max(page, 1) + '&rows=' + rows + jsonfilters + sort, {}, hsuccess, herror, hcomplete);
}

export const packageManagementEdit = (data, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/packagemanagement';
    callApi(url, { data }, hsuccess, herror, hcomplete);
}

export const packageFeatureEdit = (data, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/packagefeatureedit';
    callApi(url, { data }, hsuccess, herror, hcomplete);
}

export const packageManagementList = (page, filters, sortDef, rows, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/packagemanagementlist';
    var sort = "";
    if (sortDef) {
        Object.keys(sortDef).map((key, index) => {
            sort += "&sidx=" + key;
            if (sortDef[key])
                sort += "&sord=desc";
        });
    }
    var jsonfilters = filters ? "&" + encodeFilters(filters) : "";
    callApi(url + '?page=' + Math.max(page, 1) + '&rows=' + rows + jsonfilters + sort, {}, hsuccess, herror, hcomplete);
}
export const packageFeatureList = (page, filters, sortDef, rows, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/packagefeatures';
    var sort = "";
    if (sortDef) {
        Object.keys(sortDef).map((key, index) => {
            sort += "&sidx=" + key;
            if (sortDef[key])
                sort += "&sord=desc";
        });
    }
    var jsonfilters = filters ? "&" + encodeFilters(filters) : "";
    callApi(url + '?page=' + Math.max(page, 1) + '&rows=' + rows + jsonfilters + sort, {}, hsuccess, herror, hcomplete);
}

export const licensemanagementList = (page, filters, sortDef, rows, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/licensemanagementlist';
    var sort = "";
    if (sortDef) {
        Object.keys(sortDef).map((key, index) => {
            sort += "&sidx=" + key;
            if (sortDef[key])
                sort += "&sord=desc";
        });
    }
    var jsonfilters = filters ? "&" + encodeFilters(filters) : "";
    callApi(url + '?page=' + Math.max(page, 1) + '&rows=' + rows + jsonfilters + sort, {}, hsuccess, herror, hcomplete);
}

export const licenseInvoiceLineList = (page, filters, sortDef, rows, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/licenseinvoicelinelist';
    var sort = "";
    if (sortDef) {
        Object.keys(sortDef).map((key, index) => {
            sort += "&sidx=" + key;
            if (sortDef[key])
                sort += "&sord=desc";
        });
    }
    var jsonfilters = filters ? "&" + encodeFilters(filters) : "";
    callApi(url + '?page=' + Math.max(page, 1) + '&rows=' + rows + jsonfilters + sort, {}, hsuccess, herror, hcomplete);
}
export const parametreList = (page, filters, sortDef, rows, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/personelparametregruplist';
    var sort = "";
    if (sortDef) {
        Object.keys(sortDef).map((key, index) => {
            sort += "&sidx=" + key;
            if (sortDef[key])
                sort += "&sord=desc";
        });
    }
    var jsonfilters = filters ? "&" + encodeFilters(filters) : "";
    callApi(url + '?page=' + Math.max(page, 1) + '&rows=' + rows + jsonfilters + sort, {}, hsuccess, herror, hcomplete);
}
export const parametresEdit = (data, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/parametersEdit';
    callApi(url, { data }, hsuccess, herror, hcomplete);
}
export const parametresCopy = (model, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/parametregrupkopyala';
    callApi(url, { data:model }, hsuccess, herror, hcomplete);
}
export const creditPrices = (pkg, hsuccess, herror, hcomplete) => {
    const url = PATH + 'bulut/creditprices?pkg='+pkg;
    callApi(url, {}, hsuccess, herror, hcomplete);
}
export const edocCreditList = (page, filters, sortDef, rows, hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/edoccredits';
    var sort = "";
    if (sortDef) {
        Object.keys(sortDef).map((key, index) => {
            sort += "&sidx=" + key;
            if (sortDef[key])
                sort += "&sord=desc";
        });
    }
    var jsonfilters = filters ? "&" + encodeFilters(filters) : "";
    callApi(url + '?page=' + Math.max(page, 1) + '&rows=' + rows + jsonfilters + sort, {}, hsuccess, herror, hcomplete);
}
export const edocCreditCheck = (hsuccess, herror, hcomplete) => {
    const url = PATH + 'site/edochck';
    callApi(url, {method:'HEAD'}, hsuccess, herror, hcomplete);
}

export const saveOrderBulut = (data, hsuccess, herror, hcomplete) => {
    const url = "api/bulut/save";
    callApi(url, { data }, hsuccess, herror, hcomplete);
}
export const updateOrderBulut = (proposalId, impAmount, description, discount, hsuccess, herror, hcomplete) => {
    const url = "api/bulut/updateproposal";
    const data = {
        proposalId: proposalId,
        implementationAmount: impAmount,
        description: description,
        discount: discount
    };
    callApi(url, { data }, hsuccess, herror, hcomplete);
}
export const getProposalDetails = (proposalId, hsuccess, herror, hcomplete) => {
    const url = 'api/bulut/getproposaldetails';
    callApi(url + '?id=' + proposalId, {}, hsuccess, herror, hcomplete);
}
export const abortProposal = (proposalId, abortReason, hsuccess, herror, hcomplete) => {
    const url = 'api/bulut/abortproposal';
    const data = { abortReason: abortReason };
    callApi(url + '?id=' + proposalId, { data }, hsuccess, herror, hcomplete);
}
export const proposalList = (page, filters, sortDef, rows, hsuccess, herror, hcomplete) => {
    const url = PATH + 'bulut/proposal';
    var sort = "";
    if (sortDef) {
        Object.keys(sortDef).map((key, index) => {
            sort += "&sidx=" + key;
            if (sortDef[key])
                sort += "&sord=desc";
        });
    }
    var jsonfilters = filters ? "&" + encodeFilters(filters) : "";
    callApi(url + '?page=' + Math.max(page, 1) + '&rows=' + rows + jsonfilters + sort, {}, hsuccess, herror, hcomplete);
}